import { Trans } from '@lingui/macro'
import JSBI from 'jsbi'
import { Pair } from 'xdxswap-sdk'
import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components/macro'

import { ButtonPrimary } from '../../components/Button'
import Card from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import FullPositionCard from '../../components/PositionCard'
import Row, { RowBetween } from '../../components/Row'
import { Dots, Wrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { BIG_INT_ZERO } from '../../constants/misc'
import useToggledVersion from '../../hooks/useToggledVersion'
import { useV2Pairs } from '../../hooks/useV2Pairs'
import { useActiveWeb3React } from '../../hooks/web3'
import { useStakingInfo } from '../../state/stake/hooks'
import { useDerivedSwapInfo } from '../../state/swap/hooks'
import { toV2LiquidityToken, useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { HideSmall, ThemedText } from '../../theme'
import AppBody from '../AppBody'
const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const EmptyProposals = styled.div`
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default function Pool() {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toV2LiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  // show liquidity even if its deposited in rewards contract
  const stakingInfo = useStakingInfo()
  const stakingInfosWithBalance = stakingInfo?.filter((pool) =>
    JSBI.greaterThan(pool.stakedAmount.quotient, BIG_INT_ZERO)
  )
  const stakingPairs = useV2Pairs(stakingInfosWithBalance?.map((stakingInfo) => stakingInfo.tokens))

  // remove any pairs that also are included in pairs with stake in mining pool
  const v2PairsWithoutStakedAmount = allV2PairsWithLiquidity.filter((v2Pair) => {
    return (
      stakingPairs
        ?.map((stakingPair) => stakingPair[1])
        .filter((stakingPair) => stakingPair?.liquidityToken.address === v2Pair.liquidityToken.address).length === 0
    )
  })

  // const ON_L2 = chainId && L2_CHAIN_IDS.includes(chainId)
  const toggledVersion = useToggledVersion()
  const { allowedSlippage } = useDerivedSwapInfo(toggledVersion)
  return (
    <>
      <AppBody>
        <SwapHeader allowedSlippage={allowedSlippage} />
        <SwapPoolTabs active={'pool'} />
        <Wrapper>
          <AutoColumn gap="lg" justify="center">
            <AutoColumn gap="1.2rem" style={{ width: '100%' }}>
              <TitleRow padding={'0'}>
                <HideSmall>
                  <ThemedText.Body
                    style={{
                      marginTop: '0.5rem',
                      fontWeight: 500,
                      justifySelf: 'flex-start',
                    }}
                  >
                    <Trans>Your liquidity</Trans>
                  </ThemedText.Body>
                </HideSmall>
              </TitleRow>

              {!account ? (
                <Card padding="40px">
                  <ThemedText.Body color={theme.text3} textAlign="center">
                    <Trans>Connect to a wallet to view your liquidity.</Trans>
                  </ThemedText.Body>
                </Card>
              ) : v2IsLoading ? (
                <EmptyProposals>
                  <ThemedText.Body color={theme.text3} textAlign="center">
                    <Dots>
                      <Trans>Loading</Trans>
                    </Dots>
                  </ThemedText.Body>
                </EmptyProposals>
              ) : allV2PairsWithLiquidity?.length > 0 || stakingPairs?.length > 0 ? (
                <>
                  {v2PairsWithoutStakedAmount.map((v2Pair) => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                  {stakingPairs.map(
                    (stakingPair, i) =>
                      stakingPair[1] && ( // skip pairs that arent loaded
                        <FullPositionCard
                          key={stakingInfosWithBalance[i].stakingRewardAddress}
                          pair={stakingPair[1]}
                          stakedBalance={stakingInfosWithBalance[i].stakedAmount}
                        />
                      )
                  )}
                  <Row style={{ gap: 20 }}>
                    <ButtonPrimary as={Link} to="/add/ETH">
                      <Trans>Add Liquidity</Trans>
                    </ButtonPrimary>
                    <ButtonPrimary as={Link} to="/pool/find">
                      <Trans>Import V2 Pool</Trans>
                    </ButtonPrimary>
                  </Row>
                </>
              ) : (
                <>
                  <EmptyProposals>
                    <ThemedText.Body color={theme.text3} textAlign="center">
                      <Trans>No liquidity found.</Trans>
                    </ThemedText.Body>
                  </EmptyProposals>
                  <Row style={{ gap: 20 }}>
                    <ButtonPrimary as={Link} to="/add/ETH">
                      <Trans>Add Liquidity</Trans>
                    </ButtonPrimary>
                    <ButtonPrimary as={Link} to="/pool/find">
                      <Trans>Import V2 Pool</Trans>
                    </ButtonPrimary>
                  </Row>
                </>
              )}
            </AutoColumn>
          </AutoColumn>
        </Wrapper>
      </AppBody>
    </>
  )
}
